@font-face {
  font-family: "Averta-Regular";
  src: url("./assets/fonts/averta/Averta-Regular.eot");
  src: url("./assets/fonts/averta/Averta-Regular.ttf") format("truetype"),
    url("./assets/fonts/averta/Averta-Regular.woff") format("woff"),
    url("./assets/fonts/averta/Averta-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Averta-Extrabold";
  src: url("./assets/fonts/averta/Averta-Extrabold.eot");
  src: url("./assets/fonts/averta/Averta-Extrabold.ttf") format("truetype"),
    url("./assets/fonts/averta/Averta-Extrabold.woff") format("woff"),
    url("./assets/fonts/averta/Averta-Extrabold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

a {
  text-decoration: none;
  color: black;
}
