.background-test {
  position: absolute;
  top: 40px;
  left: 40px;
  bottom: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: white;
  z-index: 2;
  transform: translate(-50%, -50%);
  transition: all 1s;
}

.background-test.animated-test {
  width: 2200px;
  height: 2200px;
}
